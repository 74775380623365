<template>
  <b-form>
    <b-row>
      <b-col
        v-for="(data, key) in params"
        :key="key"
        md="2"
      >
        <b-form-group
          :label="data"
          :label-for="key"
        >
          <b-form-input
            :id="key"
            v-model="item[key]"
            :placeholder="data"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-for="(data, key) in {
          start_time: 'Horário Inicial',
          end_time: 'Horário Final',
        }"
        :key="key"
        md="2"
      >
        <b-form-group
          :label="data"
          :label-for="key"
        >
          <b-form-input
            :id="key"
            v-model="item[key]"
            :placeholder="data"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
    BForm, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue';

const params = {
    max_ticket: 'Ticket Máximo',
    total_sales: 'Venda Total',
    outside_hour: 'Fora de Hora',
    mean_ticket: 'Ticket Médio',
    same_card: 'Mesmo Cartão',
    not_approved: 'Não Aprovado',
    fallback: 'Fallback',
    insert_mode: 'Insert Mode',
    typed: 'Digitada',
    international: 'Internacional',
    ecommerce_fail: 'Não Aprovada Ecommerce',
};

export default {
    components: {
        BForm, BFormGroup, BFormInput, BRow, BCol,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            params,
        };
    },
};
</script>
