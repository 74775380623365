import methods from '@/enums/methods';
import TransactionMonitoringService from './base';

class SpecificParams extends TransactionMonitoringService {
    show(ec) {
        return this.fetch({
            url: `monitoring/specific-param/${ec}`,
        });
    }

    update(ec, payload) {
        return this.fetch({
            url: `monitoring/specific-param/${ec}`,
            method: methods.PUT,
            data: payload,
        });
    }
}

export default new SpecificParams();
