import methods from '@/enums/methods';
import TransactionMonitoringService from './base';

class DefaultParamService extends TransactionMonitoringService {
    show(mcc) {
        return this.fetch({
            url: `${this.baseUrl}/default-param/${mcc}`,
        });
    }

    update({ mcc, payload }) {
        return this.fetch({
            data: payload,
            url: `${this.baseUrl}/default-param/${mcc}`,
            method: methods.PUT,
        });
    }
}

export default new DefaultParamService();
