<template>
  <b-tabs pills>
    <b-tab>
      <template #title>
        <span class="font-weight-bold">Parametros Gerais</span>
      </template>
      <default-params />

    </b-tab>

    <b-tab>
      <template #title>
        <span class="font-weight-bold">Parametros Especificos</span>
      </template>
      <specific-params />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import SpecificParams from './SpecificParam.vue'
import DefaultParams from './DefaultParams.vue'

export default {
  components: {
    BTab,
    BTabs,
    SpecificParams,
    DefaultParams,
  },
}
</script>
