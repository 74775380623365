<template>
  <b-row>
    <b-col
      class="m-auto"
      md="3"
    >
      <b-card>
        <b-form-group
          label="MCC"
          label-for="mcc"
        >
          <b-input-group>
            <b-form-input
              v-model="mcc"
              placeholder="MCC"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchDefaultParam"
              >
                Procurar
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </b-form-group>
      </b-card>

    </b-col>
    <b-col
      v-if="show"
      md="12"
    >
      <b-card>
        <p>Nome: {{ item.mcc.name }}</p>
        <p>MCC: {{ item.mcc.code }}</p>
        <param-form :item="item" />
        <b-button
          variant="primary"
          @click="update"
        >
          Salvar
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue';
import ParamForm from './components/Form.vue';
import DefaultParamService from '@/service/transaction_monitoring/default_params';

export default {
    components: {
        BButton,
        BCard,
        ParamForm,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
    },

    data() {
        return {
            mcc: '',
            item: {},
            show: false,
        };
    },

    watch: {
        mcc() {
            this.show = false;
        },
    },

    methods: {
        async searchDefaultParam() {
            const { mcc } = this;
            this.callLoading(true);
            const { data, status } = await DefaultParamService.show(mcc);
            this.callLoading(false);
            if (status === 200) {
                this.item = data;
                this.show = true;
            } else {
                this.modalError(status === 404 ? 'Digite um MCC válido' : 'MCC não possui parametros especificos');
            }
        },

        async update() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualiar os parametros gerais?');

            if (!isConfirmed) return;
            this.callLoading(true);
            const { status } = await DefaultParamService.update({
                mcc: this.mcc,
                payload: this.item,
            });
            this.callLoading(false);

            if (status !== 200) {
                this.modalError('Não foi possível alterar');
                return;
            }

            this.modalSuccess('Atualizado com sucesso');
        },
    },
};
</script>
