<template>
  <b-row>
    <b-col
      class="m-auto"
      md="3"
    >
      <b-card>
        <b-form-group
          label="EC"
          label-for="ec"
        >
          <b-input-group>
            <b-form-input
              v-model="ec"
              placeholder="EC"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchSpecificParam"
              >
                Procurar
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </b-form-group>
      </b-card>

    </b-col>
    <b-col
      v-if="show"
      md="12"
    >
      <b-card>
        <p>Nome: {{ item.merchant.name }}</p>
        <p>Razão Social: {{ item.merchant.legal_tax_name }}</p>
        <p>EC: {{ item.merchant.establishment_id }}</p>
        <param-form :item="item" />
        <b-button
          variant="primary"
          @click="update"
        >
          Salvar
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue';
import ParamForm from './components/Form.vue';
import { getEc } from '@/service/transaction_monitoring/ec';
import SpecificParamService from '@/service/transaction_monitoring/specify_params';
import DefaultParamService from '@/service/transaction_monitoring/default_params';

export default {
    components: {
        BButton,
        BCard,
        ParamForm,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
    },

    data() {
        return {
            ec: '',
            item: {},
            show: false,
        };
    },

    watch: {
        ec() {
            this.show = false;
        },
    },

    methods: {
        async searchSpecificParam() {
            const { ec } = this;

            this.callLoading(true);
            const { data, status } = await SpecificParamService.show(ec);
            this.callLoading(false);

            if (status === 200) {
                this.item = data.data;
                this.show = true;
            } else if (status === 400) {
                this.getDefaultParam();
            } else {
                this.modalError('Digite um EC válido');
            }
        },

        async update() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente atualiar os parametros gerais?');

            if (!isConfirmed) return;
            this.callLoading(true);
            const { status } = await SpecificParamService.update(this.ec, this.item);
            this.callLoading(false);

            if (status !== 200) {
                this.modalError('Não foi possível alterar');
                return;
            }

            this.modalSuccess('Atualizado com sucesso');
        },

        async getDefaultParam() {
            this.callLoading(true);
            const { data: { data: merchant } } = await getEc(this.ec);

            const { data } = await DefaultParamService.show(merchant.mcc.code);
            this.item = {
                ...data,
                merchant,
            };

            this.show = true;
            this.callLoading(false);
        },
    },
};
</script>
